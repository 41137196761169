import React from 'react';
import { Icon } from '@iconify/react';
import upworkIcon from '@iconify-icons/simple-icons/upwork';
import './SidePanelComponent.scss'
import vignesh from '../../assets/images/vignesh.jpeg'
import { useDispatch } from 'react-redux';
import $ from 'jquery';
import { analytics } from '../../Firebase/FireBaseComponent'

const SidePanelComponent = () => {

  const dispatch = useDispatch();

  const changeSection = (e) => {
    
    document.getElementById("about").className = 'nav-default hvr-grow';
    document.getElementById("education").className = 'nav-default hvr-grow'; 
    document.getElementById("portfolio").className = 'nav-default hvr-grow'; 
    document.getElementById("testimonials").className = 'nav-default hvr-grow'; 
    document.getElementById("contact").className = 'nav-default hvr-grow'; 

    document.getElementById(e.currentTarget.id).className = 'nav-selected hvr-grow'

    dispatch({type:"SET_SECTION",payload:e.currentTarget.id})

    if(window.innerWidth < 1001){
      if ( $('#nav_options').is(':visible') ) {
        $('#nav_options').hide();
      }
    }

    analytics.logEvent(e.currentTarget.id);
  }

  const logGa = (e) => {
    analytics.logEvent(e.currentTarget.id);
  }

  const toggleNav = (e) => {
    if ( $('#nav_options').is(':visible') ) {
      $('#nav_options').hide();
    } else {
      $('#nav_options').show();
    }
  }

  return (
    <div className="sidebar-container">
        <a href="/"><img src={vignesh} className="sidebar-img hvr-glow" alt="Vignesh Sudhakar" /></a>
        <div className="title-container">
          <div className="sidebar-name">Vignesh S</div>
          <div className="sidebar-sub-name">Software Developer</div>
        </div>
        <div id="mobile_menu" onClick={e => toggleNav(e)} className="mobile-only" style={{float: 'right', fontSize: '35px', cursor: 'pointer', color: '#ffffff'}}><span className="bx bx-menu" title="Menu"></span></div>
        <div className="sidebar-social-container web-only">
          <a id="upwork" target="_blank" rel="noreferrer" onClick={e => logGa(e)} href="https://www.upwork.com/fl/vigneshsudhakar" className="social-icon hvr-grow"><Icon className="social-mid" icon={upworkIcon} /></a>
          <a id="linkedIn" target="_blank" rel="noreferrer" onClick={e => logGa(e)} href="https://www.linkedin.com/in/vignesh-sudhakar/" className="social-icon hvr-grow"><i className="bx bxl-linkedin social-mid" title="LinkedIn"></i></a>
          <a id="Instagram" target="_blank" rel="noreferrer" onClick={e => logGa(e)} href="https://www.instagram.com/sharva_appu/" className="social-icon hvr-grow"><i className="bx bxl-instagram social-mid" title="Instagram"></i></a>
          <a id="Twitter" target="_blank" rel="noreferrer" onClick={e => logGa(e)} href="https://twitter.com/Sharva_Appu" className="social-icon hvr-grow"><i className="bx bxl-twitter social-mid" title="Twitter"></i></a>
          <a id="Facebook" target="_blank" rel="noreferrer" onClick={e => logGa(e)} href="https://www.facebook.com/Vigneshsudhakar/" className="social-icon hvr-grow"><i className="bx bxl-facebook social-right" title="Facebook"></i></a>
          <a id="EMail" href="mailto:me@vigneshsudhakar.com" onClick={e => logGa(e)} className="social-icon hvr-grow"><i className="bx bx-mail-send social-right" title="E-Mail"></i></a>
        </div>
        <div id="nav_options" className="sidebar-nav-container">
          <div id="portfolio" onClick={e => changeSection(e)} className="nav-selected hvr-grow"><h3>PORTFOLIO</h3></div>
          <br/>
          <div id="testimonials" onClick={e => changeSection(e)} className="nav-default hvr-grow"><h3>TESTIMONIALS</h3></div>
          <br/>
          <div id="contact" onClick={e => changeSection(e)} className="nav-default hvr-grow"><h3>CONTACT</h3></div>
          <br/>
          <div id="about" onClick={e => changeSection(e)} className="nav-default hvr-grow"><h3>ABOUT</h3></div>
          <br/>
          <div id="education" onClick={e => changeSection(e)} className="nav-default hvr-grow"><h3>EDUCATION</h3></div>
        </div>
      </div>
  );
}

export default SidePanelComponent;