const initState = {
    section: 'portfolio',
}

export const reducer = (globalState = initState, action) => {
    if(action.type==="SET_SECTION"){
        return {
            ...globalState,
            section:action.payload
        }
    }
    return globalState
}

export default reducer