import React from 'react';

import inventure from '../assets/images/inventure.JPG'

const InventureAcademy = () => {

    return (
        <div className="edu-container">
            <div className="edu-sub-container">
                <div className="edu-img-container">
                    <a href="http://inventureacademy.com/" title="Inventure Academy" target="_blank" rel="noreferrer"><img src={inventure} className="edu-img js-hvr-rotate-360" alt="Inventure Academy"/></a>
                </div>
                <div className="edu-content">
                    <div className="edu-content-padding">
                        <div className="edu-title">Inventure Academy</div>
                        <div className="edu-sub-title hvr-underline-from-center"><a target="_blank" rel="noreferrer" href="http://inventureacademy.com/">Inventure Academy</a></div><br />
                        <div className="edu-sub-title">2019</div>
                        <div className="edu-text">
                            Inventure Academy is an international day school in Bangalore. 
                            It was established by the India Learning Foundation in 2005, and since then has risen to the top strata of schools in the country. 
                            We offer Pre-Kindergarten to Senior Secondary (Pre K-12) education, and are affiliated to the Cambridge Assessment International Education (CAIE) – IGCSE, 
                            A/AS levels, and the Council for the Indian School Certificate Examination (CISCE) – ICSE and ISC.  
                            Inventure Academy is ranked #3 in India and #2 in Karnataka and Bangalore (EducationWorld – C-Fore Survey 2019).  
                        </div>
                        <div style={{marginTop: '20px', fontSize: '14px'}}>Building Tools</div>
                        <div className="edu-text">
                            <ul className="skills-ul">
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Angular</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Typescript</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Firebase</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> HTML</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> CSS</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> JavaScript</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> jQuery</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Responsive Design</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Bitbucket</div></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default InventureAcademy;