import React from 'react';

const skills = [
    "Python",
    "React",
    "Angular",
    "Ionic",
    "ASP.NET",
    "MySQL",
    "JavaScript",
    "CSS",
    "Windows",
    "Linux",
    "Sublime Text",
    "Web Design",
    "Responsive Design",
    "Adaptive Design",
    "Software Dev",
    "jQuery",
    "HTML",
    "Java",
    "Objective C",
    "JSON",
    "Facebook API",
    "Twitter API",
    "Reddit API",
    "Stripe API",
    "Custom API",
    "Websockets",
    "Google Maps API",
    "Google Charts API",
    "Paypal API",
    "MVC",
    "Git",
    "Node.js",
    "Socket.io",
    "WordPress",
]

const AboutComponent = () => {

    return (
      <>
        <div className="content-title">ABOUT VIGNESH</div>
        <div style={{marginTop: '15px', fontSize: '16px'}}>
            <i className='bx bxs-info-circle' style={{marginRight: '12px'}}></i>Bachelors in Computer Science
        </div>
        <div style={{marginTop: '15px', fontSize: '16px'}}>
            <i className='bx bxs-info-circle' style={{marginRight: '12px'}}></i>Lives in Chennai, TamilNadu
        </div>
        <div style={{marginTop: '15px', fontSize: '16px'}}>
            <i className='bx bxs-info-circle' style={{marginRight: '12px'}}></i>Experience: 4+ Years
        </div>
        <div style={{marginTop: '15px', fontSize: '16px'}}>
            <i className='bx bxs-info-circle' style={{marginRight: '12px'}}></i>Fun Fact: My code is guaranteed 100% mistrake free.
        </div>
        <div style={{marginTop: '40px', fontSize: '16px'}}>
            Hello World! My name is Vignesh Sudhakar. 
            I am a software engineer with 4+ years experience building/deploying web and mobile applications. 
            I got my Bachelors degree in Computer Science from Anna University in 2018. 
            I am passionate about web and mobile development in all forms and like building apps that can help others. 
            Outside of work I am constantly teaching myself and learning how to build apps with various platforms, frameworks, languages, etc. 
            My other hobbies include: gaming, music, riding and more!
        </div>
        {
            /*
                <div style={{marginTop: '40px', marginBottom: '25px', color: '#1a1a1a', fontSize: '18px', fontFamily: '&#39;Montserrat&#39'}}>SKILLS</div>
                <div style={{display: 'inline-block'}}>
                    <ul className="skills-ul">
                        {
                            skills.map((skill, i) => { 
                                return <li key={i}><div className="hvr-grow"><i className='bx bxs-badge-check' style={{marginRight: '6px'}}></i> {skill}</div></li>
                            })
                        }
                    </ul>
                </div>
            */
        }
      </>
    );
}

export default AboutComponent;