import React from 'react';

import koios from '../assets/images/koios.JPG'

const KoiosIntelliswift = () => {

    return (
      <>
        <div className="edu-container">
            <div className="edu-sub-container">
                <div className="edu-img-container">
                    <a href="https://testserver-54c19.web.app" title="Koios" target="_blank" rel="noreferrer"><img src={koios} className="edu-img js-hvr-rotate-360" alt="Koios"/></a>
                </div>
                <div className="edu-content">
                    <div className="edu-content-padding">
                        <div className="edu-title">Koios Intelliswift</div>
                        <div className="edu-sub-title hvr-underline-from-center"><a target="_blank" rel="noreferrer" href="https://testserver-54c19.web.app">Koios Intelliswift</a></div>
                        <div className="edu-sub-title">2020</div>
                        <div className="edu-text">
                            At Intelliswift, we are leaders in delivering Digital Product Engineering, Data Management & Analytics, 
                            and Talent Solutions to enable more innovative and efficient businesses. Our strategic global locations and 
                            delivery centers are home to multidisciplinary teams of technology experts who partner with clients to solve 
                            today’s most complex challenges and deliver valuable insights, solutions, and results that impact their business positively. 
                            With customer excellence at the core, Intelliswift has established itself as a top service provider, delivering award-winning 
                            professional services to over 130+ Fortune 100 and Fortune 500 organizations. 
                        </div>
                        <div style={{marginTop: '20px', fontSize: '14px'}}>Building Tools</div>
                        <div className="edu-text">
                            <ul className="skills-ul">
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> HTML</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> CSS</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> JavaScript</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> jQuery</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Firebase</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Responsive Design</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Github</div></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </>
    );
}

export default KoiosIntelliswift;