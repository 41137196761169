import React from 'react';

import jhuma from '../assets/images/jhuma.png'

const Jhumasinha = () => {

    return (
        <div className="edu-container">
            <div className="edu-sub-container">
                <div className="edu-img-container">
                    <a href="https://www.jhumasinha.com" title="Jhumasinha" target="_blank" rel="noreferrer"><img src={jhuma} className="edu-img js-hvr-rotate-360" alt="Jhumasinha"/></a>
                </div>
                <div className="edu-content">
                    <div className="edu-content-padding">
                        <div className="edu-title">Jhumasinha</div>
                        <div className="edu-sub-title hvr-underline-from-center"><a target="_blank" rel="noreferrer" href="https://www.jhumasinha.com">Jhumasinha</a></div><br />
                        <div className="edu-sub-title">2020</div>
                        <div className="edu-text">
                            The “Mathemagician” Golden Flame award was awarded to me by The Telegraph in 2011 for my love for Mathematics and for making it fun and easy for students. 
                            However being a mathematics teacher since 2000, getting trained by the Council of Indian School Certificate Examination as a Master Trainer and conducting 
                            workshops of mathematics for teachers and students throughout the country, has not completely fulfilled me. I felt that in spite of being a Mathemagician, 
                            I could not set right the calculation of the purpose of my life which is not only to create magic in mathematics teaching and learning but also to equip children 
                            with the tools to create magic in their own lives . I am not being able to multiply my joys which are being constantly subtracted from my life on seeing the children 
                            being unable to cope with life,unable to accept defeat/failure and unable to find true happiness. They are being constantly driven by a craze for achievement rather 
                            than a desire for fulfillment in their lives.
                            <br/>
                            Hence the entire purpose of my life is to help all children to live, to learn and to love. 
                            Besides making mathematics fun, I want to leave behind a meaningful legacy for the children. 
                            Thus I have taken up the challenge of providing life skills to every child so that they can bloom with grace wherever life plants them.
                        </div>
                        <div style={{marginTop: '20px', fontSize: '14px'}}>Building Tools</div>
                        <div className="edu-text">
                            <ul className="skills-ul">
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> HTML</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> CSS</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> JavaScript</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> jQuery</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Responsive Design</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Github</div></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Jhumasinha;