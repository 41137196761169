import React from 'react';

import melting from '../assets/images/melting.JPG'

const MeltingHearts = () => {

    return (
        <div className="edu-container">
            <div className="edu-sub-container">
                <div className="edu-img-container">
                    <a href="https://meltinghearts.co/" title="Melting Hearts" target="_blank" rel="noreferrer"><img src={melting} className="edu-img js-hvr-rotate-360" alt="Melting Hearts"/></a>
                </div>
                <div className="edu-content">
                    <div className="edu-content-padding">
                        <div className="edu-title">Melting Hearts</div>
                        <div className="edu-sub-title hvr-underline-from-center"><a target="_blank" rel="noreferrer" href="https://meltinghearts.co/">Melting Hearts</a></div><br />
                        <div className="edu-sub-title">2020</div>
                        <div className="edu-text">
                            E Commerce application developed using Shopify to sell Dry Fruits for quality conscious Indian consumers along with payment gateway integration and inventory management.
                        </div>
                        <div style={{marginTop: '20px', fontSize: '14px'}}>Building Tools</div>
                        <div className="edu-text">
                            <ul className="skills-ul">
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Shopify</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> HTML</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> CSS</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Responsive Design</div></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MeltingHearts;