import React from 'react';
import annauniv from '../assets/images/annauniv.png'
import kshss from '../assets/images/kshss.JPG'

const EducationComponent = () => {

    return (
      <>
        <div className="content-title">EDUCATION</div>
        <div className="edu-container">
            <div className="edu-sub-container">
                <div className="edu-img-container">
                    <img src={annauniv} className="edu-img js-hvr-rotate-360" alt="Anna University"/>
                </div>
                <div className="edu-content">
                    <div className="edu-content-padding">
                        <div className="edu-title">Anna University</div>
                        <div className="edu-sub-title">2014 - 2018</div>
                        <div className="edu-sub-title hvr-underline-from-center"><a target="_blank" rel="noreferrer" href="https://www.annauniv.edu">annauniv.edu</a></div>
                        <div className="edu-text">I graduated in May 2018 from Anna University with a Bachelors degree in Computer Science.</div>
                    </div>
                </div>
            </div>
        </div>
        <div className="edu-separator"></div>
        <div className="edu-container">
            <div className="edu-sub-container">
                <div className="edu-img-container">
                    <img src={kshss} className="edu-img js-hvr-rotate-360" alt="Kannada Sangha Higher Secondary School"/>
                </div>
                <div className="edu-content">
                    <div className="edu-content-padding">
                        <div className="edu-title">Kannada Sangha Higher Secondary School</div>
                        <div className="edu-sub-title">2008 - 2014</div>
                        <div className="edu-sub-title hvr-underline-from-center"><a target="_blank" rel="noreferrer" href="http://www.kannadasanghaschools.org/">kannadasanghaschools.org</a></div>
                        <div className="edu-text">I graduated high school in 2014 at Kannada Sangha Higher Secondary School in Chennai.</div>
                    </div>
                </div>
            </div>
        </div>
      </>
    );
}

export default EducationComponent;