import React from 'react';

import nk from '../assets/images/nk.svg'

const Nithyakarma = () => {

    return (
      <>
        <div className="edu-container">
            <div className="edu-sub-container">
                <div className="edu-img-container">
                    <a href="https://nithyakarma.com" title="Nithyakarma" target="_blank" rel="noreferrer"><img src={nk} className="edu-img js-hvr-rotate-360" alt="Nithyakarma"/></a>
                </div>
                <div className="edu-content">
                    <div className="edu-content-padding">
                        <div className="edu-title">Nithyakarma</div>
                        <div className="edu-sub-title hvr-underline-from-center"><a target="_blank" rel="noreferrer" href="https://nithyakarma.com">Nithya Karma</a></div><br />
                        <div className="edu-sub-title">2020</div>
                        <div className="edu-text">
                            Nithya Karma's Avani Avittam audio offering gives you the same experience as having a Vadhyar giving us instructions sitting right beside us.
                        </div>
                        <div className="edu-text">
                            <ul>
                                <li>
                                    Seamlessly delivered audio to 800 users simultaneously based on their given inputs such as their ancestral details to perform the rituals.
                                </li>
                                <li>
                                    Categorization of the audio files to be fetched for users.
                                </li>
                                <li>
                                    Admin dashboard to manage users, audio files and notifications.
                                </li>
                                <li>
                                    Ability to purchase required materials for the rituals online including delivery.
                                </li>
                                <li>
                                    Ability to track their package.
                                </li>
                                <li>
                                    Payment gateway using razorpay.
                                </li>
                                <li>
                                    Email notifications (Otp, invoice, delivery confirmation, reminders etc) using Sendinblue.
                                </li>
                                <li>
                                    Authentication using Google Firebase Authentication
                                </li>
                                <li>
                                    Deployed Backend in AWS Linux EC2 instance using Nginx and Gunicorn.
                                </li>
                                <li>
                                    Message queuing system using AWS SQS & Celery.
                                </li>
                            </ul>
                        </div>
                        <div style={{marginTop: '20px', fontSize: '14px'}}>Building Tools</div>
                        <div className="edu-text">
                            <ul className="skills-ul">
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> ReactJS</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Firebase</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> HTML</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> CSS</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> JavaScript</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> jQuery</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Python</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Django</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Sendinblue</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> RazorPay</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Google Analytics</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> AWS S3</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Linux</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Gunicorn</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Nginx</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> AWS RDS</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> MYSQL</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Responsive Design</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Bitbucket</div></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </>
    );
}

export default Nithyakarma;