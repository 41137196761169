import React from 'react';

import gomathi from '../assets/images/gomathi.jpg'

const GomathiTraders = () => {

    return (
      <>
        <div className="edu-container">
            <div className="edu-sub-container">
                <div className="edu-img-container">
                    <img src={gomathi} className="edu-img js-hvr-rotate-360" alt="GomathiTraders"/>
                </div>
                <div className="edu-content">
                    <div className="edu-content-padding">
                        <div className="edu-title">Gomathi Traders</div>
                        <div className="edu-sub-title">2020</div>
                        <div className="edu-text">
                            Application developed to manage inventory and distribution for an oil distribution vendor.
                        </div>
                        <div className="edu-text">
                            <ul>
                                <li>
                                    Admin Dashboard developed using ReactJS/Python Flask and mysql database to manage users who can access the application, 
                                    add porducts and categorize them, view orders placed, select orders to be delivered for the day, add and manage vendors/customers.
                                </li>
                                <li>
                                    Billing system for on spot clients.
                                </li>
                                <li>
                                    Mobile application developed using Ionic React which connects to the same backend and database as admin dashboard for distribution purpose, 
                                    lists all the shops, ability to add shops, place orders and confirm delivery with billing system.
                                </li>
                                <li>
                                    Deployed Backend in AWS Linux EC2 instance using Nginx and Gunicorn.
                                </li>
                            </ul>
                        </div>
                        <div style={{marginTop: '20px', fontSize: '14px'}}>Building Tools</div>
                        <div className="edu-text">
                            <ul className="skills-ul">
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> ReactJS</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Firebase</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> HTML</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> CSS</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> JavaScript</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> jQuery</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Ionic - React</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Python</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Flask</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> AWS S3</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Linux</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Gunicorn</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Nginx</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> AWS RDS</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> MYSQL</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Responsive Design</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Bitbucket</div></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </>
    );
}

export default GomathiTraders;