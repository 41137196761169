import React from 'react';
import { useSelector } from 'react-redux';
import AboutComponent from '../../Components/AboutComponent'
import EducationComponent from '../../Components/EducationComponent'
import PortfolioComponent from '../../Components/PortfolioComponent'
import TestimonialComponent from '../../Components/TestimonialComponent'
import ContactComponent from '../../Components/ContactComponent'

const ContentSectionComponent = () => {

  let section = useSelector((globalState)=>{
    return globalState.section
  })

  return (
    <div className="content-container-padding">
        <div className="content-sub-container">
          {
            section === "about"
            ?
              <AboutComponent />
            :
              null
          }

          {
            section === "education"
            ?
              <EducationComponent />
            :
              null
          }

          {
            section === "portfolio"
            ?
              <PortfolioComponent />
            :
              null
          }

          {
            section === "testimonials"
            ?
              <TestimonialComponent />
            :
              null
          }

          {
            section === "contact"
            ?
              <ContactComponent />
            :
              null
          }
          
        </div>
    </div>
  );
}

export default ContentSectionComponent;