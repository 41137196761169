import React from 'react';

import intelliswift from '../assets/images/intelli.JPG'

const Intelliswift = () => {

    return (
        <div className="edu-container">
            <div className="edu-sub-container">
                <div className="edu-img-container">
                    <a href="https://intelliswift-homepage.web.app" title="Intelliswift" target="_blank" rel="noreferrer"><img src={intelliswift} className="edu-img js-hvr-rotate-360" alt="Intelliswift"/></a>
                </div>
                <div className="edu-content">
                    <div className="edu-content-padding">
                        <div className="edu-title">Intelliswift</div>
                        <div className="edu-sub-title hvr-underline-from-center"><a target="_blank" rel="noreferrer" href="https://intelliswift-homepage.web.app">Intelliswift</a></div>
                        <div className="edu-sub-title">2020</div>
                        <div className="edu-text">
                            Intelliswift is a global leader in Digital Product Engineering, Data Management & Analytics, and MSP/VMS Staffing Solutions. 
                            Our niche technology SME's across global delivery centers solve complex challenges and deliver valuable insights and solutions that drive greater business value. 
                            With customer excellence at the core, Intelliswift is a top service provider, delivering award-winning professional services to over 130+ Fortune 100 and Fortune 500 organizations.
                        </div>
                        <div style={{marginTop: '20px', fontSize: '14px'}}>Building Tools</div>
                        <div className="edu-text">
                            <ul className="skills-ul">
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> HTML</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> CSS</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> JavaScript</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> jQuery</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Firebase</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Responsive Design</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Github</div></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Intelliswift;