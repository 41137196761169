import React from 'react';

import gkc from '../assets/images/gkc.png'

const Taxonomy = () => {

    return (
      <>
        <div className="edu-container">
            <div className="edu-sub-container">
                <div className="edu-img-container">
                    <a href="https://taxonomy-e2101.web.app/" title="Taxonomy" target="_blank" rel="noreferrer"><img src={gkc} className="edu-img js-hvr-rotate-360" alt="Taxonomy"/></a>
                </div>
                <div className="edu-content">
                    <div className="edu-content-padding">
                        <div className="edu-title">Taxonomy</div>
                        <div className="edu-sub-title hvr-underline-from-center"><a target="_blank" rel="noreferrer" href="https://taxonomy-e2101.web.app/">Taxonomy</a></div><br />
                        <div className="edu-sub-title hvr-underline-from-center">
                            <a target="_blank" rel="noreferrer" href="https://drive.google.com/file/d/17P5ezPqpBu2FnbQvqEaGeH5GSVIXkott/view?usp=sharing">Taxonomy Android Apk</a>
                        </div>
                        <div className="edu-sub-title">2020</div>
                        <div className="edu-text">
                            Cross platform mobile application with mobile website developed using Ionic.
                        </div>
                        <div style={{marginTop: '20px', fontSize: '14px'}}>Building Tools</div>
                        <div className="edu-text">
                            <ul className="skills-ul">
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Ionic</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> React</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Capacitor</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Firebase</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Google Analytics</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> HTML</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> CSS</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> JavaScript</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> jQuery</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Responsive Design</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Bitbucket</div></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </>
    );
}

export default Taxonomy;