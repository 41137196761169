import React from 'react';

import sakura from '../assets/images/sakura.JPG'

const SakuraFresh = () => {

    return (
        <div className="edu-container">
            <div className="edu-sub-container">
                <div className="edu-img-container">
                    <a href="https://sakurafresh.com/" title="Sakura Fresh" target="_blank" rel="noreferrer"><img src={sakura} className="edu-img js-hvr-rotate-360" alt="Sakura Fresh"/></a>
                </div>
                <div className="edu-content">
                    <div className="edu-content-padding">
                        <div className="edu-title">Sakura Fresh</div>
                        <div className="edu-sub-title hvr-underline-from-center"><a target="_blank" rel="noreferrer" href="https://sakurafresh.com/">Sakura Fresh</a></div><br />
                        <div className="edu-sub-title">2019</div>
                        <div className="edu-text">
                            It all started with a simple vision - enriching the health of our global consumers by offering a wide variety of sustainable beverage and culinary 
                            food choices for present and for future generations.
                            <br/>
                            Headquartered in Yokohama, Japan, Sakurafresh Beverage and Food Private Limited is a global manufacturer of beverage and culinary 
                            food products for conscious consumers.
                        </div>
                        <div style={{marginTop: '20px', fontSize: '14px'}}>Building Tools</div>
                        <div className="edu-text">
                            <ul className="skills-ul">
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> HTML</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> CSS</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> JavaScript</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> jQuery</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Responsive Design</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Github</div></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SakuraFresh;