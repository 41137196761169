import React from 'react';

import nano from '../assets/images/nano.png'

const NanoMediaCreatives = () => {

    return (
      <>
        <div className="edu-container">
            <div className="edu-sub-container">
                <div className="edu-img-container">
                    <a href="https://nanomediacreatives.com/" title="Nano Media Creatives" target="_blank" rel="noreferrer"><img src={nano} className="edu-img js-hvr-rotate-360" alt="Nano Media Creatives"/></a>
                </div>
                <div className="edu-content">
                    <div className="edu-content-padding">
                        <div className="edu-title">Nano Media Creatives</div>
                        <div className="edu-sub-title hvr-underline-from-center"><a target="_blank" rel="noreferrer" href="https://nanomediacreatives.com/">Nano Media Creatives</a></div><br />
                        <div className="edu-sub-title">2020</div>
                        <div className="edu-text">
                            An advertising agency that cuts above the clutter. 
                            We provide fast-paced, tailor-made, customer-specific deliverables to help tell your brand's story. 
                            Meet us over Beer to discuss potential collaborations!
                        </div>
                        <div style={{marginTop: '20px', fontSize: '14px'}}>Building Tools</div>
                        <div className="edu-text">
                            <ul className="skills-ul">
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> HTML</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> CSS</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> JavaScript</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> jQuery</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Responsive Design</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Bitbucket</div></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </>
    );
}

export default NanoMediaCreatives;