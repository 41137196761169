import React from 'react';

import interview from '../assets/images/interview.jpg'

const Interview = () => {

    return (
      <>
        <div className="edu-container">
            <div className="edu-sub-container">
                <div className="edu-img-container">
                    <img src={interview} className="edu-img js-hvr-rotate-360" alt="Interview"/>
                </div>
                <div className="edu-content">
                    <div className="edu-content-padding">
                        <div className="edu-title">AI Interview Global Private Limited</div>
                        <div className="edu-sub-title">2020</div>
                        <div className="edu-text">
                            Multi Tenant SAAS application to ease up the recruitment process for small scale and large scale industries.
                        </div>
                        <div className="edu-text">
                            <ul>
                                <li>
                                    Ability to templatize interview process to be conducted by the recruiters.
                                </li>
                                <li>
                                    Schedule and Conduct automated interviews and collect the data from the interview process for further analysis and report generation.
                                </li>
                                <li>
                                    Frontend(ReactJS)/backend(Python Flask) development and database design with multiple databases.
                                </li>
                                <li>
                                    Email and SMS notifications using AWS SES and AWS SNS respectively. 
                                </li>
                                <li>
                                    Message queuing system using AWS SQS.
                                </li>
                                <li>
                                    Deployed Backend in AWS Linux EC2 instance using Nginx and Gunicorn.
                                </li>
                            </ul>
                        </div>
                        <div style={{marginTop: '20px', fontSize: '14px'}}>Building Tools</div>
                        <div className="edu-text">
                            <ul className="skills-ul">
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> ReactJS</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Firebase</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> HTML</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> CSS</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> JavaScript</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> jQuery</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Python</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Flask</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> WebRTC</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> AWS S3</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> AWS RDS</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> AWS SQS</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> AWS SNS</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> AWS SES</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Linux</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Gunicorn</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Nginx</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> MYSQL</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Responsive Design</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Bitbucket</div></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </>
    );
}

export default Interview;