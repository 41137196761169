import React from 'react';

//Project Imports
import Antler from '../Projects/Antler'
import LearningPaths from '../Projects/LearningPaths'
import Bytbond from '../Projects/Bytbond'
import RepairSquad from '../Projects/RepairSquad'
import Bidlight from '../Projects/Bidlight'
import KoiosIntelliswift from '../Projects/KoiosIntelliswift'
import Intelliswift from '../Projects/Intelliswift'
import Taxonomy from '../Projects/Taxonomy'
import RepublicW4 from '../Projects/RepublicW4'
import NanoMediaCreatives from '../Projects/NanoMediaCreatives'
import Nithyakarma from '../Projects/Nithyakarma'
import FirstQA from '../Projects/FirstQA'
import InventureAcademy from '../Projects/InventureAcademy'
import Himitsulab from '../Projects/Himitsulab'
import Jhumasinha from '../Projects/Jhumasinha'
import SVSTransports from '../Projects/SVSTransports'
import SakuraFresh from '../Projects/SakuraFresh'
import MeltingHearts from '../Projects/MeltingHearts'
import Bigfday from '../Projects/Bigfday'
import GomathiTraders from '../Projects/GomathiTraders'
import Interview from '../Projects/Interview'
import VigneshSudhakar from '../Projects/VigneshSudhakar'

const PortfolioComponent = (props) => {

    return (
      <>
        <div className="content-title">PORTFOLIO</div>

        {
          /*
            <div style={{fontSize: '16px'}}>
                These are some of the projects that I have worked on using the below mentioned tools and technologies, feel free to check them out, reach out to me incase you have any questions regarding the same.
                <br />
                <br />
                <b>*Note:</b> The projects listed here are only the ones not covered under NDA, if you have requirements which require an NDA, I will be happy to respect them.
              </div>
          */
        }

            <Antler {...props} />
            <div className="edu-separator"></div>

            <LearningPaths {...props} />
            <div className="edu-separator"></div>

            <Bytbond {...props} />
            <div className="edu-separator"></div>

            <RepairSquad {...props} />
            <div className="edu-separator"></div>

            <Bidlight {...props} />
            <div className="edu-separator"></div>
            
            <KoiosIntelliswift {...props} />
            <div className="edu-separator"></div>
            
            <Intelliswift {...props} />
            <div className="edu-separator"></div>
            
            <Taxonomy {...props} />
            <div className="edu-separator"></div>
            
            <RepublicW4 {...props} />
            <div className="edu-separator"></div>
            
            <NanoMediaCreatives {...props} />
            <div className="edu-separator"></div>
            
            <Nithyakarma {...props} />
            <div className="edu-separator"></div>
            
            <FirstQA {...props} />
            <div className="edu-separator"></div>

            <InventureAcademy {...props} />
            <div className="edu-separator"></div>
            
            <Himitsulab {...props} />
            <div className="edu-separator"></div>
            
            <Jhumasinha {...props} />
            <div className="edu-separator"></div>
            
            <SVSTransports {...props} />
            <div className="edu-separator"></div>
            
            <SakuraFresh {...props} />
            <div className="edu-separator"></div>
            
            <MeltingHearts {...props} />
            <div className="edu-separator"></div>

            <Bigfday {...props} />
            <div className="edu-separator"></div>

            <GomathiTraders {...props} />
            <div className="edu-separator"></div>

            <Interview {...props} />
            <div className="edu-separator"></div>

            <VigneshSudhakar {...props} />
						
      </>
    );
}

export default PortfolioComponent;