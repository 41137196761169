import React from 'react';

import bigfday from '../assets/images/bigfday.png'

const Bigfday = () => {

    return (
      <>
        <div className="edu-container">
            <div className="edu-sub-container">
                <div className="edu-img-container">
                    <a href="https://www.bigfday.com" title="FirstQA" target="_blank" rel="noreferrer"><img src={bigfday} className="edu-img js-hvr-rotate-360" alt="Bigfday"/></a>
                </div>
                <div className="edu-content">
                    <div className="edu-content-padding">
                        <div className="edu-title">Bigfday</div>
                        <div className="edu-sub-title hvr-underline-from-center"><a target="_blank" rel="noreferrer" href="https://www.bigfday.com">Bigfday</a></div><br />
                        <div className="edu-sub-title">2018 - 2020</div>
                        <div className="edu-text">
                            BigFday is an online marketplace for event services. 
                            Be it a wedding, birthday, corporate event or just a night out with friends, 
                            BigFday helps occasional and professional planners with the required services for an event. 
                            Currently, serving in Chennai we work with all 500+ venues and 100s of professionals in the event industry. 
                            So the next occasion in your family or business - Enter BigFday. 
                        </div>
                        <div className="edu-text">
                            <ul>
                                <li>
                                    CRM/CMS to track status of leads, automatic assignment of leads to salesperson, Image categorization module, property availability check and so on.
                                </li>
                                <li>
                                    Marketplace for users to search for properties/venues based on their search criteria such as the number of guests, budget, location and etc.
                                </li>
                                <li>
                                    Dashboard for property/venue managers to check on their leads and reports and etc.
                                </li>
                                <li>
                                    Price comparison, ability to check the availability of a property/venue on their desired date.
                                </li>
                                <li>
                                    Message queuing system using AWS SQS.
                                </li>
                                <li>
                                    The application was developed using ASP.Net (C#, ASPX, MVC, Html, css, javascript, mysql, linq)
                                </li>
                                <li>
                                    Deployed all 3 applications in a Windows Server using IIS.
                                </li> 
                            </ul>
                        </div>
                        <div style={{marginTop: '20px', fontSize: '14px'}}>Building Tools</div>
                        <div className="edu-text">
                            <ul className="skills-ul">
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Agile Development</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> HTML</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> CSS</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> JavaScript</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> jQuery</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Firebase</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> C#</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> .NET</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> ADO.NET</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> DBMetal</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> ASPX</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> MVC</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Linq</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Google Analytics</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> AWS S3</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> AWS RDS</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Windows Server 2016</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> IIS</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> MYSQL</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Airtable</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> MSMQ</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Exotel</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Slack Integration</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Responsive Design</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Bitbucket</div></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </>
    );
}

export default Bigfday;