import React from 'react';
import { Icon } from '@iconify/react';
import upworkIcon from '@iconify-icons/simple-icons/upwork';

const ContactComponent = () => {

    return (
      <>
        <div className="content-title">CONTACT</div>

        {
            /*
                <div style={{fontSize: '16px'}}>
                    Please feel free to reach out to me on any of these platforms.
                    <br />
                    I'd love to have a conversation about anything related to tech or about my hobbies.
                </div>
            */
        }

        <div style={{marginTop: '15px', fontSize: '16px'}}>
            <span style={{marginRight: '12px', fontSize: '18px', width: '15px'}}><Icon icon={upworkIcon} /></span>
            Hire me on <a className="hvr-underline-from-center" target="_blank" rel="noreferrer" href="https://www.upwork.com/fl/vigneshsudhakar" >Upwork</a>
        </div>
        <div style={{marginTop: '15px', fontSize: '16px'}}>
            <span className="bx bxl-linkedin" style={{marginRight: '12px', fontSize: '18px', width: '15px'}}></span>
            Connect on <a className="hvr-underline-from-center" target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/vignesh-sudhakar/" >LinkedIn</a>
        </div>
        <div style={{marginTop: '15px', fontSize: '16px'}}>
            <span className="bx bxl-instagram" style={{marginRight: '12px', fontSize: '18px', width: '15px'}}></span>
            Follow me on <a className="hvr-underline-from-center" target="_blank" rel="noreferrer" href="https://www.instagram.com/sharva_appu/">Instagram</a>
        </div>
        <div style={{marginTop: '15px', fontSize: '16px'}}>
            <span className="bx bxl-twitter" style={{marginRight: '12px', fontSize: '18px', width: '15px'}}></span>
            Follow me on <a className="hvr-underline-from-center" target="_blank" rel="noreferrer" href="https://twitter.com/Sharva_Appu">Twitter</a>
        </div>
        <div style={{marginTop: '15px', fontSize: '16px'}}>
            <span className="bx bxl-facebook" style={{marginRight: '12px', fontSize: '18px', width: '15px'}}></span>
            Connect on <a className="hvr-underline-from-center" target="_blank" rel="noreferrer" href="https://www.facebook.com/Vigneshsudhakar/">Facebook</a>
        </div>	
        <div style={{marginTop: '15px', fontSize: '16px'}}>
            <span className="bx bx-mail-send" style={{marginRight: '12px', fontSize: '18px', width: '15px'}}></span>
            Mail on <a href="mailto:me@vigneshsudhakar.com" className="hvr-underline-from-center">me@vigneshsudhakar.com</a>
        </div>	
        
      </>
    );
}

export default ContactComponent;