import React from 'react';

import firstqa from '../assets/images/firstqa.svg'

const FirstQA = () => {

    return (
        <div className="edu-container">
            <div className="edu-sub-container">
                <div className="edu-img-container">
                    <a href="https://firstqa.com" title="FirstQA" target="_blank" rel="noreferrer"><img src={firstqa} className="edu-img js-hvr-rotate-360" alt="FirstQA"/></a>
                </div>
                <div className="edu-content">
                    <div className="edu-content-padding">
                        <div className="edu-title">FirstQA</div>
                        <div className="edu-sub-title hvr-underline-from-center"><a target="_blank" rel="noreferrer" href="https://firstqa.com">FirstQA</a></div><br />
                        <div className="edu-sub-title">2020</div>
                        <div className="edu-sub-title">English - Japanese</div>
                        <div className="edu-text">
                            FirstQA Systems is a Japan Headquartered Consulting and IT Services organization with transformation solutions in today’s connected world. 
                            At FirstQA Systems, they firmly believe that cloud technologies and partnership based collaborative working model with our clients are fundamental 
                            to the success of major transformation initiatives.
                            <br />
                            Their solutions and services are centric around Blockchain, Digital Transformation and Project Management Services.
                        </div>
                        <div style={{marginTop: '20px', fontSize: '14px'}}>Building Tools</div>
                        <div className="edu-text">
                            <ul className="skills-ul">
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> HTML</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> CSS</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> JavaScript</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> jQuery</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Responsive Design</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Github</div></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FirstQA;