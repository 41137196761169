import React from 'react';

import svs from '../assets/images/svs.png'

const SVSTransports = () => {

    return (
        <div className="edu-container">
            <div className="edu-sub-container">
                <div className="edu-img-container">
                    <a href="http://svstransports.com/" title="SVS Transports" target="_blank" rel="noreferrer"><img src={svs} className="edu-img js-hvr-rotate-360" alt="SVS Transports"/></a>
                </div>
                <div className="edu-content">
                    <div className="edu-content-padding">
                        <div className="edu-title">SVS Transports</div>
                        <div className="edu-sub-title hvr-underline-from-center"><a target="_blank" rel="noreferrer" href="http://svstransports.com/">SVS Transports</a></div><br />
                        <div className="edu-sub-title">2018</div>
                        <div className="edu-text">
                            Started the business with a single truck, we have progressed a long way into having a strong hold and establishing our roots with various chemical and 
                            solvent industry clients like MRF and AKZONOBEL
                            <br/>
                            We have served our clients with a dominant role in quality service, which has led us to celebrate a successful 25 years in this industry leading to 
                            advancement and branching in various sectors like warehousing and storage.
                        </div>
                        <div style={{marginTop: '20px', fontSize: '14px'}}>Building Tools</div>
                        <div className="edu-text">
                            <ul className="skills-ul">
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> HTML</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> CSS</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> JavaScript</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> jQuery</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Responsive Design</div></li>
                                <li><div className="hvr-grow"><span className="bx bxs-badge-check" style={{marginRight: '6px'}}></span> Github</div></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SVSTransports;