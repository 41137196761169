import React from 'react';

import ramya from '../assets/images/ramya.jpg'
import mouldi from '../assets/images/mouldi.png'
import pharmacy18 from '../assets/images/logo-blue.svg'
import sabin from '../assets/images/sabin.jpg'
import tomer from '../assets/images/tomer.png'
import mustaq from '../assets/images/mustaq.jpg'
import harisankar from '../assets/images/harisankar.JPG'
import empty from '../assets/images/empty.png'

const TestimonialComponent = () => {

    return (
      <>
        <div className="content-title">CLIENT TESTIMONIALS</div>
        <div className="edu-container">
            <div className="edu-sub-container">
                <div className="edu-img-container">
                    <a href="https://republicw4.com/" title="republicw4" target="_blank" rel="noreferrer">
                        <img src={mustaq} className="edu-img js-hvr-rotate-360" alt="Mustaq Tappewale"/>
                    </a>
                </div>
                <div className="edu-content">
                    <div className="edu-content-padding">
                        <div className="edu-title">Mustaq Tappewale</div>
                        <div className="edu-sub-title hvr-underline-from-center"><a target="_blank" rel="noreferrer" href="https://republicw4.com/">Republic W4</a></div>
                        <div className="edu-text">
                            <blockquote>
                                "We have recently had our new website completed by Vignesh. 
                                I have to say that he is absolutely brilliant. 
                                Not only he is professional but knowledgable and helpful too. 
                                Our process took couple of months to complete, mainly due to our need to change things around, 
                                alter content and add items. 
                                Not once did Vignesh ever sound or look as if he had enough of us, he simply offered his expert advice, 
                                took on board our concerns and carried out the extra work. If your looking for a level of commitment, 
                                combined with dedication and professionalism, look no further."
                            </blockquote>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="edu-separator"></div>
        <div className="edu-container">
            <div className="edu-sub-container">
                <div className="edu-img-container">
                    <a href="https://drive.google.com/file/d/17P5ezPqpBu2FnbQvqEaGeH5GSVIXkott/view" title="taxonomy" target="_blank" rel="noreferrer">
                        <img src={ramya} className="edu-img js-hvr-rotate-360" alt="Ramya Chandran"/>
                    </a>
                </div>
                <div className="edu-content">
                    <div className="edu-content-padding">
                        <div className="edu-title">Ramya Chandran</div>
                        <div className="edu-sub-title hvr-underline-from-center"><a target="_blank" rel="noreferrer" href="https://drive.google.com/file/d/17P5ezPqpBu2FnbQvqEaGeH5GSVIXkott/view">Taxonomy</a></div>
                        <div className="edu-text">
                            <blockquote>
                            "My requirement when I was reaching out to people was slightly specific - I wanted someone that would help me develop an app for testing my research hypothesis and also enjoy working on it! 
                            Luckily word of mouth got me in touch with Vignesh. Considering that I am someone who is particular about timelines, it was really a blessing to have worked with someone who was respectful of that. 
                            I collaborated with other people before but there is always a lot of spoon feeding involved. His rare proactive sensibility solved this problem for me too! With respect to his skills and expertise, 
                            the app and his work speaks for itself. 
                            It's always different when someone involves themselves while Working on something as opposed to a name-sake functioning style and that is why I would gladly work with him again."
                            </blockquote>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="edu-separator"></div>
        <div className="edu-container">
            <div className="edu-sub-container">
                <div className="edu-img-container">
                    <a href="https://bidlight.com/" title="bidlight" target="_blank" rel="noreferrer">
                        <img src={mouldi} className="edu-img js-hvr-rotate-360" alt="Mouldi Nouri"/>
                    </a>
                </div>
                <div className="edu-content">
                    <div className="edu-content-padding">
                        <div className="edu-title">Mouldi Nouri</div>
                        <div className="edu-sub-title hvr-underline-from-center"><a target="_blank" rel="noreferrer" href="https://bidlight.com/">Bidlight</a></div>
                        <div className="edu-text">
                            <blockquote>
                                "Great to work with, very helpful, and knows his craft. I Strongly recommend Vignesh. Thank you"
                            </blockquote>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="edu-separator"></div>
        <div className="edu-container">
            <div className="edu-sub-container">
                <div className="edu-img-container">
                    <img src={harisankar} className="edu-img js-hvr-rotate-360" alt="Hari Sankar"/>
                </div>
                <div className="edu-content">
                    <div className="edu-content-padding">
                        <div className="edu-title">Hari Sankar</div>
                        <div className="edu-sub-title hvr-underline-from-center" style={{cursor: 'pointer'}}>Ibil Global Solutions</div>
                        <div className="edu-text">
                            <blockquote>
                                "Very professional and meticulous in planning, executing the tasks assigned. 
                                Works efficiently to meet the deadlines and calls out the challenges/road blocks in advance."
                            </blockquote>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="edu-separator"></div>
        <div className="edu-container">
            <div className="edu-sub-container">
                <div className="edu-img-container">
                    <a href="https://bigfday.com/" title="bigfday" target="_blank" rel="noreferrer">
                        <img src={sabin} className="edu-img js-hvr-rotate-360" alt="Sabin Rodriguez"/>
                    </a>
                </div>
                <div className="edu-content">
                    <div className="edu-content-padding">
                        <div className="edu-title">Sabin Rodriguez</div>
                        <div className="edu-sub-title hvr-underline-from-center"><a target="_blank" rel="noreferrer" href="https://bigfday.com/">BigFday</a></div>
                        <div className="edu-text">
                            <blockquote>
                                "Working with Vignesh was really good. I hired him for the development of my web application/crm, upon my trusted core team members recommendation. 
                                And I was just amazed at the results with an improvement after a month he started working on it. 
                                With his proven and dedicated development and bug fixing efforts, he not only increased my website's traffic but also reduced bounce rate, 
                                boosted up the conversion rate and also boosted my applications performance and server up time. 
                                I would definitely recommend him if you are looking for go to guy who can figure out your problems and fix them."
                            </blockquote>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="edu-separator"></div>
        <div className="edu-container">
            <div className="edu-sub-container">
                <div className="edu-img-container">
                    <img src={pharmacy18} className="edu-img js-hvr-rotate-360 pt-5" alt="Sesha Giri"/>
                </div>
                <div className="edu-content">
                    <div className="edu-content-padding">
                        <div className="edu-title">Sesha Giri</div>
                        <div className="edu-sub-title hvr-underline-from-center" style={{cursor: 'pointer'}}>Pharmacy18</div>
                        <div className="edu-text">
                            <blockquote>
                                "I was very much delighted with the amazing job that Vignesh was able to pull off within such short time. 
                                He was quick and efficient and his app development skills was definitely on the higher end and got my Admin Dashboard and E Commerce hybrid app completed shortly. 
                                Will definitely hire him in the future."
                            </blockquote>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="edu-separator"></div>
        <div className="edu-container">
            <div className="edu-sub-container">
                <div className="edu-img-container">
                    <img src={tomer} className="edu-img js-hvr-rotate-360" alt="Tomer Tarsky"/>
                </div>
                <div className="edu-content">
                    <div className="edu-content-padding">
                        <div className="edu-title">Tomer Tarsky</div>
                        <div className="edu-sub-title hvr-underline-from-center" style={{cursor: 'pointer'}}>Glimpse</div>
                        <div className="edu-text">
                            <blockquote>
                                "Vignesh did an amazing job. I am beyond impressed with what he was able to accomplish in such a short time. 
                                He worked fast and efficiently. He completed the job and surpassed my expectations, his web design, back end, and front end coding skills were that of a professional, 
                                and he got my web app up and running in only a few days and completely from scratch."
                            </blockquote>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="edu-separator"></div>
        <div className="edu-container">
            <div className="edu-sub-container">
                <div className="edu-img-container">
                    <img src={tomer} className="edu-img js-hvr-rotate-360" alt="Tomer Tarsky"/>
                </div>
                <div className="edu-content">
                    <div className="edu-content-padding">
                        <div className="edu-title">Tomer Tarsky</div>
                        <div className="edu-sub-title hvr-underline-from-center" style={{cursor: 'pointer'}}>Glimpse</div>
                        <div className="edu-text">
                            <blockquote>
                                "Vignesh did an amazing job creating a script for me to email potential clients. 
                                Now that I can simply add emails to google sheets and trigger them it's made my job so much easier and saves a lot of time. 
                                I would definitely recommend Vignesh to anyone who is creating anything online"
                            </blockquote>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="edu-separator"></div>
        <div className="edu-container">
            <div className="edu-sub-container">
                <div className="edu-img-container">
                    <img src={empty} className="edu-img js-hvr-rotate-360" alt="Humza Rabbani"/>
                </div>
                <div className="edu-content">
                    <div className="edu-content-padding">
                        <div className="edu-title">Humza Rabbani</div>
                        <div className="edu-sub-title hvr-underline-from-center" style={{cursor: 'pointer'}}>Student Tutor Portal</div>
                        <div className="edu-text">
                            <blockquote>
                                "Vignesh is a skilled programmer, clear communicator, 
                                and a very reliable person. 
                                He delivered quality work within a very short period of time and I strongly 
                                recommend working with him."
                            </blockquote>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </>
    );
}

export default TestimonialComponent;