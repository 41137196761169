import SidePanel from './Sections/SidePanel'
import ContentSection from './Sections/ContentSection'
import $ from 'jquery';
import './assets/css/pc.css'
import './assets/css/mobile.css'
import './assets/css/global.css'
import './assets/css/hover.css'

function adjustStyle(width) {
  width = parseInt(width);
  if (width > 1000) {
    $("#select-stylesheet").attr("href", "./assets/css/pc.css");
  } else {
     $("#select-stylesheet").attr("href", "./assets/css/mobile.css"); 
  }
}

$(function() {
  adjustStyle($(this).width());
  $(window).resize(function() {
    adjustStyle($(this).width());
  });
});

function App() {

  return (
    <div className="main-contents-container">
      <div className="main-contents-sub-container">
          <SidePanel />
        <div className="content-container">
          <ContentSection />
        </div>
      </div>
    </div>
  );
}

export default App;
