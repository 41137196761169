import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/analytics";

export const firebaseConfig = {
    apiKey: "AIzaSyAmczWxRRPmexNgauVVSNH_eMXPSNfb6Mw",
    authDomain: "vigneshsudhakar-8d3ac.firebaseapp.com",
    projectId: "vigneshsudhakar-8d3ac",
    storageBucket: "vigneshsudhakar-8d3ac.appspot.com",
    messagingSenderId: "873743576686",
    appId: "1:873743576686:web:8b77423bc3617f9472407f",
    measurementId: "G-VCR1Y8N9BS"
};

firebase.initializeApp(firebaseConfig);

export const analytics = firebase.analytics();